import {useEffect, useState} from 'react';

import {getActiveTheme, getEntity} from "@ftrack/web-widget";

import useSession from "./session_context";

import './App.css';
import {
    build_type_list,
    task_type_list,
    insights_status_list,
    task_status_list
} from "./config.js"

// custom components
import Graphs from "./components/Graphs";
import {
    GetStatusAndColors,
} from "./components/fQuery";


function loadStyleSheet(theme){
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = process.env.PUBLIC_URL+ '/style-' + theme + '.css';
    head.appendChild(link);
}

function App() {
    const theme= getActiveTheme()
    loadStyleSheet(theme)

    const session = useSession();
    let current_entity = getEntity()

    const [selection, setSelection] = useState(current_entity);
    const [buildTypes] = useState(build_type_list);
    const [taskTypes] = useState(task_type_list);
    const [taskStatuses] = useState(task_status_list);
    const [statusAndColor, setStatusAndColor] = useState({});

    function onFtrackWidgetUpdate(ftrack_event){
        setSelection(ftrack_event.detail.entity)
    }

    useEffect(()=>{
        window.addEventListener('ftrackWidgetUpdate', onFtrackWidgetUpdate);
        GetStatusAndColors(session, taskStatuses).then(
                 (colors)=>{
                     setStatusAndColor(colors)

                }
            )
    }, [session, taskStatuses])

   return (
    <Graphs session={session} selection={selection} statusColors={statusAndColor} assetBuildTypes={buildTypes} taskStatuses={taskStatuses} taskTypes={taskTypes} insights_statuses={insights_status_list} theme={theme}/>
  );
}

export default App;
