import {useEffect, useState} from "react";
import HorizontalStackedBars from "./HorizontalStackedBars";
import DoughnutChart from "./DoughnutChart";
import Insights from "./Insight";
import { ThreeCircles } from  'react-loader-spinner'


import {
    GetData
} from "./fQuery";

import {prepGraphData, prepInsightsData, prepDoughnutData} from "./Data";


function Graphs(props) {
    const [isLoading, setIsLoading] = useState(false);

    const statusColors = props.statusColors
    const assetBuildTypes = props.assetBuildTypes
    const taskStatuses = props.taskStatuses
    const taskTypes = props.taskTypes
    const session = props.session
    const selection = props.selection
    const insights_statuses = props.insights_statuses
    const theme=props.theme

    const [barsData, setBarsData] = useState({labels: [], datasets: []})
    const [doughnutData, setDoughnutData] = useState({labels: [], datasets: []})
    const [insightsData, setInsightsData] = useState([])

    useEffect(() => {
        setIsLoading(true)
        GetData(session, selection, assetBuildTypes, taskStatuses, taskTypes )
            .then(
                (data) => {

                    const bars_data = prepGraphData(data, assetBuildTypes, taskStatuses, statusColors, taskTypes)
                    const doughnut_data = prepDoughnutData(data, assetBuildTypes, taskStatuses, statusColors)
                    const insights_data = prepInsightsData(data, assetBuildTypes, taskStatuses, statusColors, taskTypes)

                    setBarsData(bars_data)
                    setDoughnutData(doughnut_data)
                    setInsightsData(insights_data)
                    setIsLoading(false)

                }
            )
    }, [assetBuildTypes, selection, session, statusColors, taskStatuses, taskTypes])

    const render_graphs = props.statusColors && (
        <div className='mainContainer' >
            <Insights data={insightsData} filters={insights_statuses} theme={theme}/>
            <div className='parentContainer'>
                <div className='barsContainer'>
                    <HorizontalStackedBars data={barsData} theme={theme}/>
                </div>
                <div className='doughnutContainer'>
                    <DoughnutChart data={doughnutData} theme={theme}/>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            {isLoading ? (
                <div className="spinnerBackdrop">
                    <ThreeCircles
                        height="250"
                        width="250"
                        color="#935ba2"
                        wrapperStyle={{}}
                        wrapperClass="spinner"
                        visible={true}
                        ariaLabel="three-circles-rotating"
                        outerCircleColor=""
                        innerCircleColor=""
                        middleCircleColor=""
                    />
                </div>
            ) : ''}
            { render_graphs }
        </div>
    );

}

export default Graphs