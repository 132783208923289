import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {Doughnut} from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function DoughnutChart(props) {
    const theme = props.theme
    const style_color = ((theme === 'light') ? 'black' : 'white')

    const plugins = [ChartDataLabels]

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        borderColor: style_color,
        borderWidth: 0,
        layout: {
            padding: {
              left: 10,
              right: 10,
              bottom: 10
            }
        },
        plugins: {
            legend:{
                // onClick: function(e, legendItem) {
                //   var index = legendItem.index;
                //   var ci = this.chart;
                //   var alreadyHidden = !ci.isDatasetVisible(index);
                //   console.log(ci)
                //   ci.data.datasets[0].data.forEach(function(e, i) {
                //     var meta = ci.getDatasetMeta(i);
                //     if (i !== index) {
                //         if (!alreadyHidden) {
                //             ci.setDatasetVisibility(index, false)
                //             // meta.hidden = meta.hidden === null ? !meta.hidden : null;
                //         } else {
                //             ci.setDatasetVisibility(index, true)
                //         }
                //     }
                //   });
                //
                //   ci.update();
                // },
                position: 'bottom',
                labels: {
                    color: style_color,
                }

            },
            title: {
                display: true,
                text: 'Progress Pie',
                color: style_color,
                font: {
                    weight: 'bold',
                    size: 20,
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.forEach(data => {
                        sum += data;
                    });
                    if (!value) {
                        return ''
                    }
                    return (value * 100 / sum).toFixed(2) + "%";
                },
                color: 'white',
                font: {
                    weight: 'bold'
                },
            },
            tooltip: {
                enabled: true,
                usePointStyle: true,
                callbacks: {
                    labelPointStyle: function (context) {
                        return {
                            pointStyle: 'triangle',
                            rotation: 0
                        };
                    },
                },

            }
        }

    };


    return (
        <Doughnut options={options} data={props.data} plugins={plugins}></Doughnut>
    );

}


export default DoughnutChart