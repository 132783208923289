async function GetStatusAndColors(session, taskStatuses) {

    let wantedStatusesStr = "'" + taskStatuses.join("','") + "'"
    let stats_query = `select color, name
                       from Status
                       where name in (${wantedStatusesStr})`
    const colors = await session.query(stats_query)

    const new_data = {}
    colors.data.forEach((color) => {
        new_data[color['name']] = color['color']
    })
    return new_data
}

async function getTasks(session, currentEntity, buildTypes, taskStatuses, taskTypes) {
    let buildTypesAsString = buildTypes.join("','");
    let taskStatusesAsString = taskStatuses.join("','");
    let taskTypesAsString = taskTypes.join("','");

    let query = 'select status.name, status.state.name, type.name, parent.type.name from Task ';

    if (currentEntity['type'] === 'Project') {
        query += `where project_id is "${currentEntity.id}" `;
    } else {
        query += `where ancestors.id is "${currentEntity.id}" `;
    }

    query += `and status.name in ('${taskStatusesAsString}') ` +
        `and type.name in ('${taskTypesAsString}') ` +
        `and parent.type.name in ('${buildTypesAsString}')`;
    
    const result = await session.query(query);

    return result['data'];
}

async function GetData(session, current_entity, buildTypes, taskStatuses, taskTypes) {
    console.time('getDataTime')

    let data = {};
    let ab_type_key;
    let task_type;
    let task_status;
    let task_state;

    let tasks = await getTasks(session, current_entity, buildTypes, taskStatuses, taskTypes);

    for (const task of tasks) {
        if (task) {
            task_type = task.type.name
            task_status = task.status.name
            task_state = task.status.state.name
            ab_type_key = task.parent.type.name
            data[ab_type_key] = data[ab_type_key] || {}
            data[ab_type_key][task_type] = data[ab_type_key][task_type] || {'status':{}, 'state':{}}

            data[ab_type_key][task_type]['status'][task_status] = data[ab_type_key][task_type]['status'][task_status] || 0
            data[ab_type_key][task_type]['status'][task_status] += 1;

            data[ab_type_key][task_type]['state'][task_state] = data[ab_type_key][task_type]['state'][task_state] || 0
            data[ab_type_key][task_type]['state'][task_state] += 1;
        }
    }
    console.timeEnd('getDataTime')
    return data
}


export {GetStatusAndColors, GetData};