
function Insights(props) {
    const insights_data = props.data;
    const filters = props.filters
    let insightsGrouped = {};

    insights_data.forEach(insight => {
        if (insightsGrouped[insight.label]) {
            insightsGrouped[insight.label].push(insight);
        } else {
            insightsGrouped[insight.label] = [ insight ];
        }
    });

    return (
        <div className='insights-container'>
            {Object.entries(insightsGrouped).map((insight_data, i) => {
                return [
                    <div className='insights-group' key={i}>
                        <div>
                            {insight_data[0]}
                            <div className='insights-group-inner'>
                                {insight_data[1].map((insight, j) => {
                                    if (filters.indexOf(insight.status) !== -1){
                                        if (insight.status !== 'In Progress'){
                                                return (
                                                    <Insight
                                                        value={insight.status_value}
                                                        color={insight.color}
                                                        status={insight.status}
                                                        type='status'
                                                        key={j}
                                                    />
                                                )
                                            } else {
                                                return (
                                                    <Insight
                                                        value={insight.state_value}
                                                        color={insight.color}
                                                        status={insight.status}
                                                        type='state'
                                                        key={j}
                                                    />
                                                )

                                        }
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                ]
            })}
        </div>
    )
}

function Insight(props) {
    const type=props.type

    return (
        <div className='insight-container'>
            <h1 className={`insight-value-${type}`}>{props.value}</h1>
            <div
                className='insight-status'
                style={{ backgroundColor: props.color }}
            >
                {props.status}
            </div>
        </div>
    );
}


export default Insights;