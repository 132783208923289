function prepInsightsData(data, assetBuildTypes, taskStatuses, statusColors, taskTypes) {
    const result_data = []
    assetBuildTypes.forEach((buildType) => {
        taskStatuses.forEach((taskStatus) => {

            const new_data = {}
            new_data['label'] = buildType
            new_data['status'] = taskStatus
            new_data['state'] = taskStatus

            new_data['color'] = statusColors[taskStatus]
            new_data['status_value'] = 0
            new_data['state_value'] = 0
            taskTypes.forEach((taskType) => {
                data[buildType] = data[buildType] || {}
                data[buildType][taskType] = data[buildType][taskType] || {'status':{}, 'state':{}}

                data[buildType][taskType]['status'][taskStatus] = data[buildType][taskType]['status'][taskStatus] || 0
                const task_status_data = data[buildType][taskType]['status'][taskStatus]

                data[buildType][taskType]['state'][taskStatus] = data[buildType][taskType]['state'][taskStatus] || 0
                const task_state_data = data[buildType][taskType]['state'][taskStatus]

                new_data['status_value'] += task_status_data || 0
                new_data['state_value'] += task_state_data || 0

            })
            result_data.push(new_data)
        })
    })
    return result_data
}


function prepDoughnutData(data, assetBuildTypes, taskStatuses, statusColors) {

    const new_data = {}
    new_data['labels'] = taskStatuses
    new_data['datasets'] = []

    const data_set = {
        hoverOffset: 20,
        pointStyle: "circle",
        data: [],
        backgroundColor: [],
    }

    taskStatuses.forEach((status) => {
        let accum = 0

        for (let i in data) {
            for (let j in data[i]) {
                const status_data = data[i][j]['status'][status] || 0
                accum += status_data
            }
        }
        data_set.backgroundColor.push(statusColors[status] || 0)
        data_set.data.push(accum)
    })
    new_data.datasets.push(data_set)
    return new_data
}


function prepGraphData(data, assetBuildTypes, taskStatuses, statusColors, taskTypes) {
    const new_data = {}
    new_data['labels'] = assetBuildTypes
    new_data['datasets'] = []

    const per_build_status = {}

    for (let i in data) {
        const build_type = i
        taskStatuses.forEach((status) => {

            for (let j in data[i]) {
                const task_type = j
                const status_data = data[i][j]['status'][status] || 0
                per_build_status[build_type] = per_build_status[build_type] || {}
                per_build_status[build_type][task_type] = per_build_status[build_type][task_type] || {'status':{}, 'state': {}}
                per_build_status[build_type][task_type]['status'][status] = per_build_status[build_type][task_type]['status'][status] || 0
                per_build_status[build_type][task_type]['status'][status] += status_data

            }
        })
    }

    const datas = []
    taskStatuses.forEach((taskStatus) => {
        taskTypes.forEach((taskType) => {
            const graph_data = {}

            graph_data['label'] = `${taskType}:${taskStatus}`
            graph_data['stack'] = taskType
            graph_data['data'] = []
            graph_data['backgroundColor'] = statusColors[taskStatus]
            graph_data['barPercentage']= 0.9
            graph_data['categoryPercentage']= 0.9
            graph_data['barThickness'] = 'flex'
            graph_data['borderWidth'] = 0

            assetBuildTypes.forEach((assetBuild) => {

                per_build_status[assetBuild] = per_build_status[assetBuild] || {}
                per_build_status[assetBuild][taskType] = per_build_status[assetBuild][taskType] || {'status':{}, 'state':{}}

                const task_value = per_build_status[assetBuild][taskType]['status'][taskStatus]
                graph_data.data.push(task_value)
            })
            datas.push(graph_data)

        })
    })
    new_data.datasets = datas
    return new_data

}


export {prepDoughnutData, prepGraphData, prepInsightsData}