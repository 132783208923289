import ChartPluginStacked100 from "chartjs-plugin-stacked100";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

} from 'chart.js';

import {Bar} from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartPluginStacked100,
    ChartDataLabels
);



function HorizontalStackedBars(props) {
    const theme = props.theme
    const style_color = ((theme === 'light') ? 'black' : 'white')

    const plugins = [ChartPluginStacked100, ChartDataLabels]
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            stacked100: {
                enable: true,
                replaceTooltipLabel: true,
                precision: 1,
                individual: false,
                fixNegativeScale: true
            },
            legend: {
                position: 'bottom',
                align: 'start',
                onClick: function(e, legendItem) {
                    var index = legendItem.datasetIndex;
                    var ci = this.chart;
                    var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;

                    ci.data.datasets.forEach(function(e, i) {
                      var meta = ci.getDatasetMeta(i);

                      if (i !== index) {
                        if (!alreadyHidden) {
                          meta.hidden = meta.hidden === null ? !meta.hidden : null;
                        } else if (meta.hidden === null) {
                          meta.hidden = true;
                        }
                      } else if (i === index) {
                        meta.hidden = null;
                      }
                    });

                    ci.update();
                  },
                labels: {
                    color: style_color,
                }
            },
            title: {
                display: true,
                text: 'Asset Status Report',
                color: style_color,
                font: {
                    weight: 'bold',
                    size: 20
                },
            },
            datalabels: {
                clamp: true,
                formatter: (value, context) => {
                    const data = context.chart.data;
                    const { datasetIndex, dataIndex } = context;
                    const oldVal = `${data.originalData[datasetIndex][dataIndex]}`;
                    if (oldVal == 0 || !oldVal || oldVal === '' || oldVal === 'undefined') {
                        return ['','']
                    }
                    const labels = context.dataset.label.split(':')
                    return [labels[0], oldVal]
                },
                color: 'white',
                font: {
                    size: 10,
                    weight: 'bold'
                },
            },
        },
        indexAxis: 'y',
        barThickness: 30,
        scales: {
            x: {
                 title: {
                     color: style_color,
                     display: true,
                     text: '%',
                 },
                beginAtZero: true,
                ticks: {
                    color: style_color,
                    stepSize: 1,
                    callback: function(value, index, ticks) {
                        return value;
                    }
                },
                stacked: true,
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: style_color,
                    padding: 10,
                    labelOffset: -30,
                    maxRotation: 90,
                    minRotation: 90,
                },
                stacked: true,
                grid: {
                    color: style_color,
                    display: true
                },
            }
        },
        elements: {
            bar: {
                borderWidth: 1
            }
        },
    };


    return (
        <Bar options={options} data={props.data} plugins={plugins}></Bar>
    );

}


export default HorizontalStackedBars
