    export const build_type_list = [
        "BG",
        "Character",
        "Prop"]
    export const task_type_list = [
        "Art",
        "Rigging"
    ]
    export const insights_status_list = [
        "Not Ready",
        "In Progress",
        "Approved",
    ]
    export const task_status_list = [
        'Approved',
        'Change Requested',
        'Director Review',
        'Supervisor Review',
        'Lead Review',
        'In Progress',
        'On Hold',
        'Ready',
        'Not Ready',
        'Omitted',
    ]